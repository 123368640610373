import moment from "moment";

export const Transactions = () => {
  return (
    <div className="row">
      <div className="col-12 mb-3">
        <h1 className="title mb-0 mt-2">Transactions</h1>
        <p>Manage your transactions and receipts here.</p>
      </div>

      <div className="col-12">
        <div className="table-responsive border border-2 transactions-table p-3">
          <table className="table table-borderless m-0">
            <thead>
              <tr>
                <th scope="col" className="pb-4">
                  DATE
                </th>
                <th scope="col" className="pb-4">
                  AMOUNT
                </th>
                <th scope="col" className="pb-4">
                  STATUS
                </th>
                <th scope="col" className="pb-4">
                  DUE DATE
                </th>
                <th scope="col" className="pb-4">
                  RECEIPT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment().format("MMM Do, YYYY") }</td>
                <td>$0</td>
                <td className="text-success">Paid</td>
                <td>-</td>
                <td>
                  <button className="btn btn-primary btn-sm px-3 px-lg-4">
                    Download
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
