import { useState } from "react";
import MainContainer from "../../components/main-container/main-container.component";
import "./billing.scss";
import { Overview } from "./overview";
import { Transactions } from "./transactions";

const Billing = () => {

    const [activeTab, setActiveTab] = useState('overview');

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    }

    return (
      <MainContainer>
        <div className="billing-container py-3 py-md-4 px-0 px-md-3">
          <div className="row">
            <div className="col-12">
              <button
                type="button"
                className={`btn mr-2 ${
                  activeTab === "overview"
                    ? "btn-primary"
                    : "btn-outline-secondary"
                }`}
                onClick={() => handleTabChange("overview")}
              >
                Overview
              </button>
              <button
                type="button"
                className={`btn mr-2 ${
                  activeTab === "transactions"
                    ? "btn-primary"
                    : "btn-outline-secondary"
                }`}
                onClick={() => handleTabChange("transactions")}
              >
                Transactions
              </button>
            </div>
          </div>

          { activeTab === "overview" ?
            <Overview />
          : 
            <Transactions />
          }
        </div>
      </MainContainer>
    );
}

export default Billing;