import moment from "moment";
import { formatDateString } from "../components/user-profile-popup/schedule-call-popup";

export const calculateStartTimeAndEndTimeTimeZone = (date, day, profileTimezone, loginTimezone) => {
  
  console.log("calculateStartTimeAndEndTimeTimeZone")
  console.log(date, day)

    // Define the start time in EST
    const formattedDate = formatDateString(date);

    // timezone of the currently viewing user
    const startTimeProfileUser = moment.tz(
        `${formattedDate} ${
        parseInt(day.startTime) < 10 ? `0${day.startTime}` : day.startTime
        }:00`,
        profileTimezone
    );

    const endTimeProfileUser = moment.tz(
        `${formattedDate} ${
        parseInt(day.endTime) < 10 ? `0${day.endTime}` : day.endTime
        }:00`,
        profileTimezone
    );

    // Convert the time from EST to UTC
    const startTimeInUTC = startTimeProfileUser.clone().utc();
    const endTimeInUTC = endTimeProfileUser.clone().utc();

    // Convert the start time from profile user to currently login user
    const startTimeLoginUser = startTimeInUTC.clone().tz(loginTimezone);
    const endTimeLoginUser = endTimeInUTC.clone().tz(loginTimezone);

    // Display the converted time
    console.log(
      "UTC:",
      startTimeInUTC.format("YYYY-MM-DD HH:mm:ss"),
      endTimeInUTC.format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(
      "Start Time in Profile User:",
      startTimeProfileUser.format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(
      "End Time in Profile User:",
      endTimeProfileUser.format("YYYY-MM-DD HH:mm:ss")
    );
    console.log(
      "Start Time in Login User:",
      startTimeLoginUser.format("YYYY-MM-DD HH:mm:ss dddd")
    );
    console.log(
      "End Time in Login User:",
      endTimeLoginUser.format("YYYY-MM-DD HH:mm:ss dddd")
    );

    return {
        startTimeDate: startTimeLoginUser.format("YYYY-MM-DD"),
        endTimeDate: endTimeLoginUser.format("YYYY-MM-DD"),
        startTime: startTimeLoginUser.format("HH:mm:ss"),
        endTime: endTimeLoginUser.format("HH:mm:ss"),
        startTimeDay: startTimeLoginUser.format("dddd"),
        endTimeDay: endTimeLoginUser.format("dddd"),
        start: startTimeLoginUser.format("YYYY-MM-DD HH:mm:ss"),
    };
};

export function useCalendarDays({
  availabilityHour,
  profileUserTimeZone,
  loginUserTimeZone,
  startTimeDynamicOptionList: availableDaysOption,
  currentDate
}) {

  const currentTzDate = moment.tz(currentDate, loginUserTimeZone);

  // function for calculating startTime and EndTime based on TimeZone of Profile User

  function isEndOfMonth(date) {
    // Get the end of the month for the provided date
    const endOfMonth = moment(date).endOf("month");

    // Check if the provided date is the same as the end of the month
    return formatDateString(date) === endOfMonth.format("YYYY-MM-DD");
  }

  function getMinutes(minute) {
    console.log("minute ", minute)
    if (minute >= 45) {
        return 60;
    } else if (minute >= 30) {
        return 45;
    } else if (minute >= 15) {
        return 30;
    } else if (minute >= 0) {
        return 15;
    } else {
        return 0;
    }
  }

  const getStartHours = (currentTime, startTime, tzDate) => {
    if(formatDateString(tzDate) === formatDateString(currentTzDate) && currentTime >= startTime) {
      return parseInt(currentTime.split(":")[0])
    } else {
      return parseInt(startTime.split(":")[0])
    }
  }

  function getDays(date) {

    moment.updateLocale("en", {
      week: {
        // Set the First day of week to Sunday
        dow: 0,
      },
    });

    // const day = moment.tz(date, loginUserTimeZone).day() // NEW
    const tzDate = moment.tz(date, loginUserTimeZone)
    const day = moment(tzDate.toString()).day()
    // let day = daysSwitchCase(tzDate.day()); // OLD

    // find value from object by day
    const itemFound = availabilityHour
      .filter((i) => parseInt(i.selecteDay) === day)
      .sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime));

    console.log("tzDate ", tzDate.toString())
    console.log("date ", date.toString())


    if (itemFound?.length) {
      const availableDaysOptionList = [];
      itemFound.forEach((day) => {
        const {
          startTimeDate,
          endTimeDate,
          startTime,
          endTime,
          startTimeDay,
          endTimeDay,
        } = calculateStartTimeAndEndTimeTimeZone(
          tzDate,
          day,
          profileUserTimeZone,
          loginUserTimeZone
        );

        // Convert startTime and endTime to hours
        // 2024-04-15 06:mm:ss
        const currentTime = moment.tz(currentTzDate, loginUserTimeZone).format("HH:mm A");
        //const startHour = formatDateString(tzDate) === formatDateString(currentDate) ? parseInt(currentTime.split(":")[0]) : parseInt(startTime.split(":")[0]); 

        const startHour = getStartHours(currentTime, startTime, tzDate);
        const endMinute = parseInt(endTime.split(":")[1]) || 0;
        const endHour = endMinute
          ? parseInt(endTime.split(":")[0]) + 1
          : parseInt(endTime.split(":")[0]);

          console.log(formatDateString(currentTzDate))
          console.log(startTime)
          console.log(startHour)
          console.log(endTime)

        if (startHour > endHour) {
          console.log("startHour > endHour")
          // Loop from startTime to midnight
          for (let hour = startHour; hour < 24; hour++) {
            // Set the initial minute based on the start time
            let startMinute = 0;
            if (hour === startHour) {
              startMinute = parseInt(startTime.split(":")[1]);
              startMinute = getMinutes(startMinute);
            }

            for (let minute = startMinute; minute < 60; minute += 15) {
              const formattedHour = (hour % 12 || 12)
                .toString()
                .padStart(2, "0");
              const formattedMinute = minute.toString().padStart(2, "0");
              const ampm = hour < 12 ? "AM" : "PM";
              const time = `${formattedHour}:${formattedMinute} ${ampm}`;

              availableDaysOptionList.push({
                date: startTimeDate,
                time: time,
                day: startTimeDay,
              });
            }
          }

          // Loop from midnight to endTime
          for (let hour = 0; hour < endHour; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
              const formattedHour = (hour % 12 || 12)
                .toString()
                .padStart(2, "0");
              const formattedMinute = minute.toString().padStart(2, "0");
              const ampm = hour < 12 ? "AM" : "PM";
              const time = `${formattedHour}:${formattedMinute} ${ampm}`;

              availableDaysOptionList.push({
                date: endTimeDate,
                time: time,
                day: endTimeDay,
              });

              if (
                parseInt(endTime.split(":")[0]) === hour &&
                endMinute === minute
              ) {
                break;
              }
            }
          }
        }

        if (startHour < endHour) {
          console.log("startHour < endHour ", startTime)
          for (let hour = startHour; hour < endHour; hour++) {
            // Set the initial minute based on the start time
            let startMinute = 0;
            if (hour === startHour) {
              startMinute = parseInt(startTime.split(":")[1]);
              // startMinute = getMinutes(startMinute);
            }

            for (let minute = startMinute; minute < 60; minute += 15) {
              const formattedHour = (hour % 12 || 12)
                .toString()
                .padStart(2, "0");
              const formattedMinute = minute.toString().padStart(2, "0");
              const ampm = hour < 12 ? "AM" : "PM";
              const time = `${formattedHour}:${formattedMinute} ${ampm}`;

              availableDaysOptionList.push({
                date: startTimeDate,
                time: time,
                day: startTimeDay,
              });

              if (
                parseInt(endTime.split(":")[0]) === hour &&
                endMinute === minute
              ) {
                break;
              }
            }
          }
        }
      });
      availableDaysOption.push(...availableDaysOptionList)
    }
  }

  return { getDays };
}
