import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Pages
import LoginSignUp from './pages/login-signup/login-signup.component';
import Register from './pages/register/register';
import VerifyEmail from './pages/verify-email/verify-email';
import ForgotPassword from './pages/forgot-password/forgot-password.component';
import Dashboard from './pages/dashboard/dashboard.component';
import MyProfile from './pages/my-profile/my-profile.component';
import Schedule from './pages/schedule/schedule.component';
import ChangePassword from './pages/change-password/change-password.component';
import Connections from './pages/connections/connections.component';
import MyConnections from './pages/connections/my-connections.component';
import MyPendingConnections from './pages/connections/pending-connections.component';
import FAQ from './pages/faq/faq.component';
import Feedback from './pages/feedback/feedback.component';
import Support from './pages/support/support.components';
import ManagePreferences from './pages/manage-preferences/manage-preferences.component';
import NoMatch from './pages/404/404.component';
import MyConnection from './pages/my-connection/my-connection.components';
import CallScheduled from './pages/call-schedule/call-schedule.components';
import AfterCallConnect from './pages/after-call-connect/after-call-connect.components';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions.components';
import RescheduleCall from './pages/reschedule/RescheduleCall.component';
import Unsubscribe from './pages/unsubscribe/unsubscribe.component';
import Settings from './pages/schedule/settings/settings.component';
import UnsubscribeConfirmation from './pages/unsubscribe/unsubscribe_confirmation.component';
import RecommendedMeeting from './pages/recommended-meeting/RecommendedMeeting.component';
import Billing from './pages/billing/billing.component';

function App() {
  const token = useSelector( state => state.user.token );
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          { token ? <Redirect to="/dashboard" />: <LoginSignUp /> }
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/thankyou" >
          <VerifyEmail />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route  path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/my-profile">
          <MyProfile />
        </Route>
        <Route path="/p/:id" render={(props) => <MyConnection {...props} />} />
        <Route path="/schedule">
          <Schedule />
        </Route>
        <Route path="/schedule-settings">
          <Settings />
        </Route>
        <Route path="/connections">
          <Connections />
        </Route>
        <Route path="/my-connections">
          <MyConnections />
        </Route>
        <Route path="/pending-connections">
          <MyPendingConnections />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/manage-preferences">
          <ManagePreferences />
        </Route>
        <Route path="/billing">
          <Billing />
        </Route>
        <Route path="/change-password">
          <ChangePassword />
        </Route>
        <Route path="/call-schedule">
          <CallScheduled />
        </Route>
        <Route path="/connect">
          <AfterCallConnect />
        </Route>
        <Route path="/reschedule">
          <RescheduleCall />
        </Route>
        <Route path="/recommended-meeting">
          <RecommendedMeeting />
        </Route>
        <Route path="/unsubscribe">
          <Unsubscribe />
        </Route>
        <Route path="/unsubscribe-confirmation">
          <UnsubscribeConfirmation />
        </Route>
        <Route path="/terms-and-conditions">
          <TermsAndConditions />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
