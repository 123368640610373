import { IoMdClose } from "react-icons/io";

export const AddNewCard = () => {
    return (
      <div
        className="modal fade"
        id="addNewCard"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content bg-dark px-2 pb-2">
            <div className="modal-header border-0 pb-0">
              <h4 className="modal-title text-white" id="staticBackdropLabel">
                Add New Card
              </h4>
              <button
                type="button"
                className="btn btn-link p-0 text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <IoMdClose fontSize="25px" />
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-9 pr-2">
                      <div className="form-group">
                        <label for="cardNumber" className="f-14 mb-1">
                          Card Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardNumber"
                          placeholder="**** **** **** 1234"
                        />
                      </div>

                      <div className="form-group">
                        <label for="cardName" className="f-14 mb-1">
                          Cardholder Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardName"
                          placeholder="Susan 3For3"
                        />
                      </div>
                    </div>

                    <div className="col-3 pl-2">
                      <div className="form-group">
                        <label for="cardCvv" className="f-14 mb-1">
                          CVV
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cardCvv"
                          aria-describedby="cvvHelp"
                        />
                        <small
                          id="cvvHelp"
                          class="form-text text-muted"
                          style={{ fontSize: 10 }}
                        >
                          The last 4 digits on back of the card
                        </small>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group">
                        <label for="expirationDate" className="f-14 mb-1">
                          Expiration Date
                        </label>
                        <div className="d-flex w-50">
                          <input
                            type="text"
                            className="form-control mr-2"
                            id="expirationDate"
                            placeholder="MM"
                          />
                          <input
                            type="text"
                            className="form-control"
                            id="expirationDate"
                            placeholder="YY"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer border-0">
              <button
                type="button"
                class="btn btn-secondary btn-sm bg-white text-dark"
                data-dismiss="modal"
                style={{ minWidth: 80 }}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style={{ minWidth: 80 }}
              >
                Add Card
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}