import { IoMdClose } from "react-icons/io";

export const UpgradeSubscriptionModal = () => {
    return (
      <div
        class="modal fade"
        id="upgradeSubscription"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="bg-dark modal-content px-3">
            <div class="bg-dark border-0 modal-header pb-0 px-2">
              <h4 class="modal-title text-white" id="staticBackdropLabel">
                Choose a plan that is right for you!
              </h4>
              <button
                type="button"
                class="btn btn-link p-0 text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
                <IoMdClose fontSize="25px" />
              </button>
            </div>
            <div class="modal-body bg-dark">
              <div className="row">
                <div className="col p-2">
                  <div class="card h-100">
                    <div class="card-body d-flex flex-column justify-content-between p-3">
                      <div className="pb-4">
                        <h5 class="card-title mb-1">Forever Free</h5>
                        <p class="card-subtitle text-muted f-14">
                          Perfect for beginners
                        </p>
                        <small className="text-primary">$0/monthly</small>
                        <ul className="mt-5 p-0 pl-3 f-14">
                          <li>
                            Limited but <u>POWERFUL</u>
                          </li>
                          <li>10 daily credits</li>
                        </ul>
                      </div>
                      <div className="text-center">
                        <button className="btn btn-secondary disabled f-14 w-75">
                          Current Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col p-2">
                  <div class="card h-100">
                    <div class="card-body d-flex flex-column justify-content-between p-3">
                      <div className="pb-4">
                        <h5 class="card-title mb-1">Starter</h5>
                        <p class="card-subtitle text-muted f-14">
                          Ideal for Casual Users
                        </p>
                        <small className="text-primary">$1/monthly</small>
                        <ul className="mt-5 p-0 pl-3 f-14">
                          <li>Unlimited Credits</li>
                        </ul>
                      </div>
                      <div className="text-center">
                        <button className="btn btn-primary f-14 w-75">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col p-2">
                  <div class="card h-100">
                    <div class="card-body d-flex flex-column justify-content-between p-3">
                      <div className="pb-4">
                        <h5 class="card-title mb-1">Enhanced</h5>
                        <p class="card-subtitle text-muted f-14">
                          Great for Small Teams
                        </p>
                        <small className="text-primary">$5/monthly</small>
                        <ul className="mt-5 p-0 pl-3 f-14">
                          <li>Unlimited Credits</li>
                          <li>Unlimited Tables</li>
                          {/* <li>5 Rooms</li> */}
                        </ul>
                      </div>

                      <div className="text-center">
                        <button className="btn btn-primary f-14 w-75">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col p-2">
                  <div class="card h-100">
                    <div class="card-body d-flex flex-column justify-content-between p-3">
                      <div className="pb-4">
                        <h5 class="card-title mb-1">Unlimited</h5>
                        <p class="card-subtitle text-muted f-14">
                          Best for Large Teams
                        </p>
                        <small className="text-primary">$14.95/monthly</small>
                        <ul className="mt-5 p-0 pl-3 f-14">
                          <li>Unlimited Credits</li>
                          <li>Unlimited Tables</li>
                          {/* <li>Unlimited Rooms</li> */}
                        </ul>
                      </div>

                      <div className="text-center">
                        <button className="btn btn-primary f-14 w-75">
                          Upgrade
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col p-2">
                  <div class="card h-100">
                    <div class="card-body">
                      <h5 class="card-title mb-1">Enterprise</h5>
                      <p class="card-subtitle text-muted f-14">
                        Custom Solutions
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}