import React, { useRef, useEffect } from "react";
import "./sidebar-menu.styles.scss";

import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';

import {
  FiHome,
  FiFileText,
  FiCalendar,
  FiUsers,
  FiHelpCircle,
  FiFlag,
  FiShare2,
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiGift,
  FiLinkedin,
  FiCreditCard,
} from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { BiSupport } from "react-icons/bi"
import { BsGear } from "react-icons/bs";
import { FaRegUser } from "react-icons/fa";
import { IoPeopleOutline } from "react-icons/io5";

import { ShareProfilePropup } from "../../utilities/utilities";
import axios from "axios";
import { toast } from 'react-toastify';
import clientConfig from "../../env";

const SidebarMenu = (props) => {
  
  const dispatch = useDispatch();
  const sidebarRef = useRef()
  const ProfileKey = useSelector((state) => state.user.profile_unique_key);
  const InviteCredit = useSelector((state) => state.user.inviteCredit);
  const userId = useSelector( (state) => state.user.userId);
  const token = useSelector((state)=> state.user.token);
  const shareLinkUrl = encodeURIComponent(`${window.location.origin}/register?invite=${ProfileKey}`);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        props?.sidebarMenuOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        props?.sidebarMenuOpenParent(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props?.sidebarMenuOpen]);

  const getLatestData = () => {
    axios.get(`${clientConfig.siteUrl}wl/v1/user/${userId}`)
      .then(res => {
        let loginInfo = { token: token }
        dispatch(setCurrentUser({ ...loginInfo, ...res.data }));
      }).catch(err => console.error(err));
  }

  const CliamCredit = () => {
    const httpbody = {
      "credit": InviteCredit,
      "user_id": userId
    }

    const configheader = {
      "Content-Type": 'application/json',
      "Authorization": `Barer ${token}`,
    }

    axios.post(`${clientConfig.siteUrl}wl/v1/cliam-credits`, httpbody, {
      headers: configheader
    }).then( response => {
      if(response.status === 200) {
        getLatestData();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }).catch( error => {
      console.log(error);
    })
  }

  return (
    <>
      <aside
        ref={sidebarRef}
        id="sidebarMenu"
        className="col-md-3 col-lg-2 px-0 d-md-block  sidebarContainer"
      >
        <nav className="sidebar">
          <div className="sidebar-sticky pt-3">
            <ul className="nav flex-column">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  exact
                  to="/dashboard"
                >
                  <FiHome />
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/my-profile"
                >
                  <FaRegUser />
                  My Profile
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/schedule"
                >
                  <FiCalendar />
                  Schedule
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/connections"
                >
                  <IoPeopleOutline />
                  Connections
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/faq"
                >
                  <FiHelpCircle />
                  FAQ
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/feedback"
                >
                  <FiFlag />
                  Feedback
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/support"
                >
                  <BiSupport />
                  Support
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/manage-preferences"
                >
                  <BsGear />
                  Preferences
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/billing"
                >
                  <FiCreditCard />
                  Billing
                </NavLink>
              </li>
            </ul>

            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-5 pt-5 mb-2 text-muted">
              <span className="text-uppercase">Share</span>
              <span className="d-flex align-items-center text-muted">
                <FiShare2 />
              </span>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="javascript.void(0)"
                  aria-label="Add a new report"
                  data-toggle="modal"
                  data-target="#personalInviteModal"
                >
                  <FiGift className="fi icon-gift" />
                  Get 20 Credits
                </a>

                {InviteCredit && InviteCredit > 0 ? (
                  <span
                    className="cliamcredit"
                    onClick={() => CliamCredit()}
                  >{`Claim +${InviteCredit} credits`}</span>
                ) : null}
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={`mailto:?subject=3for3%20Networking%20Invite%20Link&body=You%20are%20invited%20to%20a%20join%20with%20me%20${shareLinkUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <HiOutlineMail className="fi icon-twitter" />
                  Email
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link fb-xfbml-parse-ignore"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${shareLinkUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingLeft: "0.9em" }}
                >
                  <FiFacebook size="1.2em" className="fi icon-facebook" />
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://www.linkedin.com/shareArticle?url=${shareLinkUrl}&title=3For3%20Networking`}
                >
                  <FiLinkedin className="fi icon-twitter" />
                  Linkedin
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href={`https://twitter.com/intent/tweet?text=${shareLinkUrl}`}
                >
                  <FiTwitter className="fi icon-twitter" />
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </aside>
      <ShareProfilePropup ProfileKey={ProfileKey} shareLinkUrl={shareLinkUrl} />
    </>
  );
}

export default SidebarMenu;