import { IoMdClose } from "react-icons/io";

export const BuyCredits = () => {
    return (
      <div
        className="modal fade"
        id="buyCredits"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: 650}}>
          <div className="modal-content bg-dark py-2 px-2">
            <div className="modal-header border-0 pb-0 pt-2">
              <h4 className="modal-title text-white" id="staticBackdropLabel">
                Buy Credits
              </h4>
              <button
                type="button"
                className="btn btn-link p-0 text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <IoMdClose fontSize="25px" />
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label for="creditRange" className="f-14 text-white">
                    0 credits
                  </label>
                  <label for="creditRange" className="f-14 text-white">
                    $14.95
                  </label>
                </div>
                <input
                  type="range"
                  className="form-control-range"
                  id="creditRange"
                />
                <p className="text-white f-14">
                  Your credits are spent across all accounts, it is a recurring
                  subscription for credits.
                </p>
              </div>

              <div className="row">
                <div className="col-md-9 mt-3">
                  <p className="mb-1 f-14 text-white">Card Details</p>
                  <div className="card border-rounded">
                    <div className="card-body py-2 px-3">
                      <div className="row align-items-center">
                        <div className="col-9">
                          <div className="row align-items-center">
                            <div className="col">Logo</div>
                            <div className="col-8">
                              <p className="mb-1 small-text f-14">
                                **** **** **** 1234
                              </p>
                              <p className="mb-0 small-text f-14">
                                Exp. date 01/2026
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-3 text-right">
                          <button className="btn btn-primary btn-sm">
                            Default
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer border-0">
              <button
                type="button"
                class="btn btn-secondary btn-sm bg-white text-dark"
                data-dismiss="modal"
                style={{ minWidth: 80 }}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style={{ minWidth: 80 }}
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}