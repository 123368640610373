import moment from "moment";
import { AddNewCard } from "./addNewCard";
import { BuyCredits } from "./buyCredits";
import { UpgradeSubscriptionModal } from "./upgradeSubscriptionModal";

export const Overview = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 mb-3">
            <h1 className="title mb-0 mt-2">Billing</h1>
            <p>Manage your billing and credits here.</p>
          </div>

          <div className="col-lg-6">
            <div className="card payment-card border-dark border-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <p className="mb-1 f-14">Next Payment</p>
                    <h4 className="mb-1">$0</h4>
                    <p className="mb-0 f-14">
                      On {moment().add(30, "days").format("MMM Do, YYYY")}
                    </p>
                  </div>
                  <div className="col-lg-8 mt-3 mt-lg-0">
                    <p className="mb-1 f-14">Card Details</p>
                    <div className="card border-rounded">
                      <div className="card-body py-2 px-3">
                        <div className="row align-items-center">
                          <div className="col-9 col-sm-8 col-md-9">
                            <div className="row align-items-center">
                              <div className="col d-inline d-sm-none d-md-inline">
                                Logo
                              </div>
                              <div className="col-8 col-sm-12 col-md-8">
                                <p className="mb-1 small-text f-14">
                                  **** **** **** 1234
                                </p>
                                <p className="mb-0 small-text f-14">
                                  Exp. date 01/2026
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-3 col-sm-4 col-md-3 pl-0 px-sm-2 text-right">
                            <button className="btn btn-primary btn-sm">
                              Default
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-between mt-4">
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#upgradeSubscription"
                    >
                      <span className="f-14">Upgrade Subscription</span>
                    </button>

                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#addNewCard"
                    >
                      <span className="f-14">+ Add Card</span>
                    </button>
                  </div>

                  <div className="col-12">
                    <div className="form-group mt-4 mb-0">
                      <div className="d-flex justify-content-between">
                        <label for="formControlRange" className="f-14">
                          {moment().format("MMM Do, YYYY")}
                        </label>
                        <label for="formControlRange" className="f-14">
                          {moment().add(30, "days").format("MMM Do, YYYY")}
                        </label>
                      </div>
                      <div className="progress" style={{ height: 8 }}>
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: "75%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-3 mt-lg-0">
            <div className="card payment-card border-dark border-2 h-100">
              <div className="card-body d-flex flex-column justify-content-between">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <p className="mb-1 f-14">Base Credit Details</p>
                    <h4 className="mb-1">0 credits</h4>
                    <p className="mb-0 f-14">used from 250</p>
                  </div>
                  <div className="col-lg-6"></div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-between mt-4">
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#buyCredits"
                    >
                      <span className="f-14">Buy Credits</span>
                    </button>
                  </div>

                  <div className="col-12">
                    <div className="form-group mt-4 mb-0">
                      <div className="d-flex justify-content-between">
                        <label for="formControlRange" className="f-14">
                          0
                        </label>
                        <label for="formControlRange" className="f-14">
                          Unlimited
                        </label>
                      </div>
                      <div className="progress" style={{ height: 8 }}>
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: "75%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpgradeSubscriptionModal />
        <AddNewCard />
        <BuyCredits />
      </>
    );
}